* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;

}
a {
  text-decoration: none;

}
/* Стили для body */
body {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black;
}

/* Основной контейнер приложения */

/* Navbar */
.navbar {
  width: 100%;
  position: fixed; /* Заменяем absolute на fixed для фиксации */
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 80px; /* Явная высота навбара */
}

/* Стили для элементов меню */
.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-evenly; /* Равномерное распределение элементов */
  align-items: center;
  width: 100%; /* Растягиваем меню на всю ширину экрана */
  padding: 0;
  margin: 0;
}

/* Стили для ссылок в меню */
.nav-links li {
  flex-grow: 1;
  text-align: center; /* Центрирование текста внутри пунктов меню */
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 28px;
  transition: all 0.4s ease; /* Обеспечиваем плавный переход для всех свойств */
  padding: 10px;
  display: inline-block; /* Это важно для корректного срабатывания transform */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

/* Эффект при наведении на элементы меню */
.nav-links li a:hover {
  color: rgb(225, 28, 209);
  transform: scale(1.2); /* Легкое увеличение размера */
  filter: drop-shadow(0 0 10px rgba(12, 1, 1, 0.7)); /* Свечение при наведении */
}

/* Стили для иконки бургер-меню */
.burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 25px;
  width: 30px;
  position: absolute; /* Позиционирование абсолютное */
  top: 20px; /* Отступ сверху */
  right: 20px; /* Отступ справа */
  z-index: 15; /* Убедитесь, что кнопка поверх других элементов */
  transition: opacity 0.3s ease; /* Плавный переход для видимости */
}

.burger .line {
  width: 100%;
  height: 4px;
  background-color: white;
}

/* Контент */
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1;
  /* Убедитесь, что нет дополнительных отступов */
  margin: 0;
  padding: 0;
}


/* Стили для SVG */
.glowing-svg {
  width: 550px;
  margin-bottom: 20px;
  fill: white;
  stroke: white;
  stroke-width: 0.3;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5)); /* Добавление тени к SVG */
  transition: filter 0.3s ease; /* Плавный переход */
}

@keyframes glow-pulse {
  from {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.2)); /* Начальное свечение */
  }
  to {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5)); /* Максимальное свечение */
  }
}

/* Текст под SVG */
.gyoza-text {
  font-size: 68px;
  color: white;
  margin-top: 20px;
  text-align: center;
  width: 100%; /* Ширина текста совпадает с шириной родителя */
  max-width: 550px; /* Ограничиваем ширину текста, чтобы совпадала с шириной SVG */
  letter-spacing: 5px; /* Межбуквенное расстояние */
  text-transform: uppercase;
  position: relative;
  overflow: hidden; /* Обрезаем анимацию, если она выходит за пределы текста */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.gyoza-text2 {
  font-size: 18px;
  color: white;
  margin-top: 20px;
  text-align: center;
  width: 100%; /* Ширина текста совпадает с шириной родителя */
  max-width: 550px; /* Ограничиваем ширину текста, чтобы совпадала с шириной SVG */
  letter-spacing: 5px; /* Межбуквенное расстояние */
  text-transform: uppercase;
  position: relative;
  overflow: hidden; /* Обрезаем анимацию, если она выходит за пределы текста */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.warden-text {
  font-size: 48px;
  color: white;
  text-align: center;
  animation-play-state: running; /* Запуск анимации по умолчанию */
  animation: pulse 3s infinite; /* Запускаем анимацию */
  transition: all 0.5s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
.warden-text:hover {
  animation-play-state: paused;
  color: rgb(225, 28, 209); /* Меняем цвет на красный */
  transform: scale(1.4); /* Устанавливаем начальное состояние для плавности */
 
}
@keyframes pulse {
  0% {
    transform: scale(1);

  }
  50% {
    transform: scale(1.1); /* Легкое увеличение размера */


  }
  100% {
    transform: scale(1);
 
  }
}


/* Стили для мобильной версии */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in;
    transform: translateX(100%);
    text-align: center;
  }
  .glowing-svg {
    width: 350px;
   }
  
  .nav-links.open {
    transform: translateX(0%);
  }
  .nav-links.open ~ .burger {
    opacity: 0; /* Скрываем кнопку, когда меню открыто */
    pointer-events: none; /* Игнорируем клики на скрытую кнопку */
  }

  .nav-links li {
    margin: 20px 0;
  }

  .burger {
    display: flex;
  }

  .burger.open {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  /* Крестик виден только при открытии меню */
  .close-icon.show {
    display: block;
  }
}

/* Центровка меню для ПК */
@media (min-width: 768px) {
  .nav-links {
    justify-content: space-evenly; /* Равномерное распределение элементов на всю ширину */
    width: 100%;
  }

  .nav-links li {
    margin: 0; /* Убираем дополнительные отступы */
  }
}

/* Стили для крестика закрытия меню */
.close-icon {
  display: none; /* Скрыта по умолчанию */
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  z-index: 20;
}

.close-icon.show {
  display: block; /* Показывать крестик при открытии меню */
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Убедитесь, что нет отступов и границ, которые могут смещать блок */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}